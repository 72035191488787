import { format } from 'date-fns';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';

import { useBoundStore } from '@stores/BoundStore';

import { Scrollbar } from '@shared/css';
import { Commons, TransactionCommons } from '@shared/functions';

import { FontStyles } from '@constants/fontStyles';
import { IconArrowLeft, IconTimer } from '@constants/icons';

import CopyField from '@components/CopyField/CopyField';
import Modal from '@components/Modal/Modal';
import SupportingDocument from '@components/SupportingDocument/SupportingDocument';
import TransactionDetailsOverview from '@components/transaction/TransactionDetailsOverview/TransactionDetailsOverview';

interface Props {
  transaction: DbTransaction;
}

const formatDate = (dateString: string) => {
  const date = new Date(dateString);
  return `${format(date, 'EEEE, d MMMM yyyy')} at ${format(date, 'kk:mm')}`;
};

const PendingDepositModal = ({ transaction }: Props) => {
  const { t } = useTranslation();
  const closeModal = useBoundStore((state) => state.closeModal);

  const orderedKeys: (keyof DbTransaction)[] = [
    'name',
    'amount',
    'fee',
    'currency',
    'dateAndTime',
    'scheduledAt',
    'direction',
    'id',
    'status',
    'iban',
    'bicSwift',
    'bankName',
    'bankAddress',
    'bankCountry',
    'reference',
    'supportingDocument',
  ];

  return (
    <TransactionDetailModal>
      <CloseButtonSmall onClick={closeModal}>
        <IconArrowLeft />
      </CloseButtonSmall>
      <Wrapper>
        <HeadText>{t('pending_transaction')}</HeadText>
        <SubText>
          {t('pending_transaction_subheading')}
          {transaction && ' ' + transaction.tag}
        </SubText>
        <TransactionDetailsOverview
          icon={<IconTimer />}
          kind={transaction.direction}
          type={transaction && transaction.type}
          name={transaction && transaction.name}
          pending
        />
        <ContentWrapper>
          <ContentScroll>
            <Content>
              {orderedKeys.map((key) => {
                if (transaction[key] === null && key === 'scheduledAt') {
                  return (
                    <CopyField
                      oneLine={true}
                      key={key}
                      label="Scheduled Execution Date"
                      value={formatDate(transaction['dateAndTime'].toString())}
                    />
                  );
                } else if (
                  transaction[key] !== undefined &&
                  transaction[key] !== null &&
                  transaction[key] !== '' &&
                  transaction[key] !== 'managedAccountId'
                ) {
                  if (key.toString() === 'supporting_document') {
                    return (
                      <SupportingDocument
                        key={key}
                        file={transaction[key] as File}
                      />
                    );
                  } else if (key === 'dateAndTime') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="Authorization Date"
                        value={formatDate(transaction[key])}
                      />
                    );
                  } else if (key === 'direction') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="Type of Transction"
                        value={transaction[key] === 'in' ? 'Incoming' : 'Outgoing'}
                      />
                    );
                  } else if (key === 'scheduledAt') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="Scheduled Execution Date"
                        value={formatDate(transaction[key].toString())}
                      />
                    );
                  } else if (key === 'amount') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={key}
                        value={Commons.currencyFormatter(transaction[key]).toString()}
                      />
                    );
                  } else if (key === 'fee') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="fee"
                        value={Commons.currencyFormatter(transaction[key]).toString()}
                      />
                    );
                  } else if (key === 'id') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="transaction_id"
                        value={transaction[key]}
                      />
                    );
                  } else if (key === 'name') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={transaction.direction === 'in' ? 'sender' : 'beneficiary'}
                        value={transaction[key]}
                      />
                    );
                  } else if (key.toString() === 'additional_details') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={
                          transaction.direction === 'in'
                            ? 'additional_sender_details'
                            : 'additional_beneficiary_details'
                        }
                        value={transaction[key]?.toString() || ''}
                      />
                    );
                  } else if (key === 'status') {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label="transaction_status"
                        value={TransactionCommons.getStatus(transaction[key]) || transaction[key]}
                      />
                    );
                  } else if (
                    key === 'type' ||
                    key === 'externalId' ||
                    key === 'userId' ||
                    key === 'currency'
                  ) {
                    return;
                  } else {
                    return (
                      <CopyField
                        oneLine={true}
                        key={key}
                        label={key}
                        value={transaction[key]?.toString() || ''}
                      />
                    );
                  }
                }
              })}
            </Content>
          </ContentScroll>
        </ContentWrapper>
        <Footer>
          <span>
            You can either wait for our follow-up communication or contact our customer service for
            immediate clarification.
          </span>
          <br />
          <span>
            Please note that while you will still be able to send funds received prior to this
            transaction, the availability and use of funds from the reviewed transaction and all
            subsequent transactions will be restricted until all review processes are completed.
          </span>
          <br />
          <span>Thank you for your understanding and cooperation.</span>
        </Footer>
      </Wrapper>
    </TransactionDetailModal>
  );
};

export default PendingDepositModal;

const TransactionDetailModal = styled(Modal)`
  padding: 0 45px;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
`;

const HeadText = styled.div`
  ${FontStyles.h3};
  margin-bottom: 10px;
  background: ${({ theme }) => theme.palette.gradients.primaryBlackGradient};
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    margin: 60px 0 10px 0;
    ${FontStyles.h2};
  }
`;

const SubText = styled.div`
  ${FontStyles.h6};
  margin-bottom: 35px;
  color: ${({ theme }) => theme.palette.greys.darkestGrey};
  text-align: center;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    ${FontStyles.bodyMiddleGilroy};
    color: ${({ theme }) => theme.palette.primary.black};
  }
`;

const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
  height: 248px;

  @media (${({ theme }) => theme.breakpoints.tablet}) {
    height: 135px;

    & > :first-child {
      align-items: start;
      justify-content: start;

      & > :nth-child(2) > .oneLine {
        padding-top: 0;
      }
    }
  }
`;

const ContentScroll = styled.div`
  ${Scrollbar};
  overflow: hidden auto;
  padding-right: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding-right: 13px;
  }
`;

const ContentWrapper = styled.div`
  padding: 20px 10px 20px 20px;
  box-sizing: border-box;
  border: 1px solid ${(props) => props.theme.palette.greys.lightLightGrey};
  border-radius: 10px;

  @media (${(props) => props.theme.breakpoints.tablet}) {
    padding: 23px 12px 23px 25px;
    max-height: 170px;
  }
`;

const Footer = styled.div`
  padding-top: 12px;
  display: flex;
  text-align: center;
  flex-direction: column;
  box-sizing: border-box;
  ${FontStyles.bodyMiddleGilroy};
`;

export const CloseButtonSmall = styled.button`
  width: 100%;
  color: ${(props) => props.theme.palette.primary.black};
  margin-bottom: 33px;
  margin-top: 18px;

  &:hover {
    cursor: pointer;
  }

  @media (${(props) => props.theme.breakpoints.tablet}) {
    display: none;
  }
`;
