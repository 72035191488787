import { ReactElement } from 'react';
import styled, { css } from 'styled-components';

import { FontStyles } from '@constants/fontStyles';

interface Props {
  icon: ReactElement;
  kind: string;
  type: string;
  name: string;
  pending?: boolean;
}

const TransactionDetailsOverview = ({ icon, kind, type, name, pending = false }: Props) => {
  return (
    <Wrapper>
      <IconWithBackground
        kind={kind}
        $pending={pending}
      >
        {icon}
      </IconWithBackground>
      <InnerWrapper>
        <Top>{name}</Top>
        <Bottom>
          <TypeLabel>{kind === 'in' ? `Incoming ${type}` : `Outgoing ${type}`}</TypeLabel>
        </Bottom>
      </InnerWrapper>
    </Wrapper>
  );
};

export default TransactionDetailsOverview;

const Wrapper = styled.div`
  display: flex;
  background: ${(props) => props.theme.palette.greys.middleGrey};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 12px 15px;
  align-items: center;
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  width: 100%;
`;

const Top = styled.div`
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.buttonGilroyType3};
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: calc(100vw - 130px);
`;

const Bottom = styled.div`
  display: flex;
  justify-content: space-between;
  color: ${(props) => props.theme.palette.primary.black};
  ${FontStyles.bodySmallGilroy};
  gap: 5px;
  margin-top: 0;
  flex-direction: row;
`;

const IconWithBackground = styled.div<{ kind: string; $pending: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  min-width: 40px;
  min-height: 40px;
  margin-right: 10px;
  background-color: ${(props) => props.theme.palette.primary.white};

  color: ${(props) =>
    props.kind === 'in'
      ? props.theme.palette.positive.contrastGreen
      : props.theme.palette.negative.contrastRed};

  ${(props) =>
    props.$pending &&
    css`
      background-color: ${props.theme.palette.status.pendingFinishStatus};
      color: ${(props) => props.theme.palette.primary.black};
    `};
`;

const TypeLabel = styled.label`
  color: ${(props) => props.theme.palette.primary.black};
`;
